/*
 * Main Script File
 * Author: Mario Jahn
 */

import Splide from "@splidejs/splide";
import ScrollPadlock from "scroll-padlock";
const scrollPadlock = new ScrollPadlock();

// No JS Fallback
document.documentElement.classList.add("js");

document.addEventListener("DOMContentLoaded", function () {
	/*####### SCROLL PADLOCK TOGGLE #######*/
	function addClassToBody() {
		const checkbox = document.getElementById("main-menu__state");
		const body = document.body;

		checkbox.addEventListener("change", function () {
			if (checkbox.checked) {
				body.classList.add("scroll-padlock-locked");
			} else {
				body.classList.remove("scroll-padlock-locked");
			}
		});
	}
	addClassToBody();

	/*####### SHRINK HEADER #######*/
	const sentinel = document.getElementById("header-listener");
	const header = document.getElementById("header");

	const options = {
		root: document,
		rootMargin: "0px",
		threshold: 0,
	};

	const callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				header.classList.remove("header--small");
			} else {
				header.classList.add("header--small");
			}
		});
	};
	const observer = new IntersectionObserver(callback, options);
	observer.observe(sentinel);

	/*####### SPLIDE SLIDER #######*/
	if (document.querySelectorAll(".splide--home").length) {
		setTimeout(() => {
			new Splide(".splide--home", {
				type: "loop",
				pagination: true,
				paginationKeyboard: true,
				arrows: false,
				perPage: 1,
				//cover: true,
				//heightRatio: 0.5,
				drag: false,
				autoplay: true,
				interval: 6000,
				speed: 1200,
				direction: "ttb",
				height: "320px",
				breakpoints: {
					768: {
						heightRatio: 1.5,
					},
				},
			}).mount();
		}, 100);
	}

	if (document.querySelectorAll("#single-mission").length) {
		new Splide(".splide--testimonials", {
			type: "loop",
			pagination: true,
			paginationKeyboard: true,
			arrows: true,
			perPage: 3,
			speed: 1200,
			breakpoints: {
				1030: {
					perPage: 2,
				},
				540: {
					perPage: 1,
				},
			},
		}).mount();
	} else if (document.querySelectorAll(".splide--testimonials").length) {
		new Splide(".splide--testimonials", {
			type: "loop",
			pagination: true,
			paginationKeyboard: true,
			arrows: true,
			perPage: 2,
			speed: 1200,
			breakpoints: {
				540: {
					perPage: 1,
				},
			},
		}).mount();
	}

	/*####### CALCULATE SPLIDE SLIDER MIN HEIGHT #######*/
	function getLargestSlideHeight() {
		const textSlides = document.querySelectorAll(".splide__slide-txt");
		const logoSlides = document.querySelector(".splide__slide-logos");

		let largestHeight = 0;

		// Calculate largest height for text slides
		textSlides.forEach((slide) => {
			largestHeight = Math.max(largestHeight, slide.offsetHeight);
		});

		// Calculate largest height for logo slides (considering previous)
		largestHeight = largestHeight + logoSlides.offsetHeight;

		return largestHeight;
	}

	if (document.querySelectorAll(".splide--home").length) {
		let maxSlideHeight = getLargestSlideHeight();
		document.querySelector(
			".splide--home"
		).style.minHeight = `${maxSlideHeight}px`;
	}

	/*####### ADD ANIMATION CLASSES (MISSION) #######*/
	const animationOptions = {
		root: document,
		rootMargin: "0px",
		threshold: 1,
	};

	const animationCallback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add("animation");
				return;
			}

			//entry.target.classList.remove("animation");
		});
	};
	const animationObserver = new IntersectionObserver(
		animationCallback,
		animationOptions
	);
	const animateEls = document.querySelectorAll(".animate");
	animateEls.forEach((element) => animationObserver.observe(element));

	document.addEventListener("facetwp-loaded", function () {
		const animationOptions = {
			root: document,
			rootMargin: "0px",
			threshold: 1,
		};

		const animationCallback = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("animation");
					return;
				}

				//entry.target.classList.remove("animation");
			});
		};
		const animationObserver = new IntersectionObserver(
			animationCallback,
			animationOptions
		);
		const animateEls = document.querySelectorAll(".animate");
		animateEls.forEach((element) => animationObserver.observe(element));
	});

	/*####### BACK TO TOP BUTTON #######*/
	/*const backToTopButton = document.getElementById("back-to-top");

	// Helper function to check for scroll position
	function isScrolledEnough() {
		const windowHeight = window.innerHeight; // Get window height
		const scrollTop = window.scrollY; // Get scroll position

		// Show button if scrolled past a certain point (adjust value as needed)
		return scrollTop > windowHeight;
	}

	// Event listener for scroll event
	window.addEventListener("scroll", function () {
		const shouldShowButton = isScrolledEnough();

		if (shouldShowButton) {
			backToTopButton.classList.remove("hidden"); // Remove hidden class to show button
		} else {
			backToTopButton.classList.add("hidden"); // Add hidden class to hide button
		}
	});

	// Optional: Hide button on scrolling down (comment out if not needed)
	window.addEventListener("scroll", function () {
		const scrollDirection = window.scrollY > prevScrollPos ? "down" : "up";
		prevScrollPos = window.scrollY; // Update previous scroll position

		if (scrollDirection === "down" && !isScrolledEnough()) {
			backToTopButton.classList.add("hidden"); // Hide button on scroll down (before reaching threshold)
		}
	});

	// Variable to store previous scroll position (optional)
	let prevScrollPos = 0;*/

	let prevScrollPos = getCurrentScroll();
	let ticking = false;
	const backToTopButton = document.getElementById("back-to-top"); // Assuming "back-to-top" is the ID of your button

	window.addEventListener("scroll", function () {
		let currentScrollPos = getCurrentScroll();

		// Throttle execution using requestAnimationFrame
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = true;

				const shouldShowButton = isScrolledEnough();

				if (shouldShowButton) {
					backToTopButton.classList.remove("hidden");
				} else {
					backToTopButton.classList.add("hidden");
				}

				// Update class based on scroll direction and position
				if (prevScrollPos > currentScrollPos) {
					backToTopButton.classList.remove("header--scrolldown"); // Scrolling Up
				} else if (currentScrollPos > 20) {
					backToTopButton.classList.add("header--scrolldown"); // Scrolling Down (past threshold)
				}

				prevScrollPos = currentScrollPos;
				ticking = false;
			});
		}
	});

	// Helper function to check for scroll position
	function isScrolledEnough() {
		const windowHeight = window.innerHeight; // Get window height
		const scrollTop = window.scrollY; // Get scroll position

		// Show button if scrolled past a certain point (adjust value as needed)
		return scrollTop > windowHeight;
	}

	function getCurrentScroll() {
		return window.pageYOffset || document.documentElement.scrollTop;
	}
}); /* end of as page load scripts */
